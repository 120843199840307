import React, {useRef} from 'react';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {DynamicStep, DynamicStepComponentProps} from '../../StepsManager/Components/DynamicStep';
import {StepHeader} from '../../StepsManager/Components/StepHeader';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {StepImplementationProps, StepState} from '../../../../../types/app.types';

export enum PaymentAndPlaceOrderStepDataHook {
  root = 'PaymentAndPlaceOrderStepDataHook.root',
  header = 'PaymentAndPlaceOrderStepDataHook.header',
  open = 'PaymentAndPlaceOrderStepDataHook.open',
  empty = 'PaymentAndPlaceOrderStepDataHook.empty',
}

const InternalPaymentAndPlaceOrderStep = ({index}: DynamicStepComponentProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const localeKeys = useLocaleKeys();

  const {
    stepsManagerStore: {stepsList},
  } = useControllerProps();

  const stepState = stepsList[index].state;

  return (
    <>
      <StepHeader
        index={index}
        dataHook={PaymentAndPlaceOrderStepDataHook.header}
        label={localeKeys.checkout.payment()}
        ref={headerRef}
      />
      {stepState === StepState.OPEN && (
        <div data-hook={PaymentAndPlaceOrderStepDataHook.open}>
          <span>Step is open</span>
        </div>
      )}
    </>
  );
};

export const PaymentAndPlaceOrderStep = ({index}: StepImplementationProps) => {
  return (
    <DynamicStep
      index={index!}
      dataHook={PaymentAndPlaceOrderStepDataHook.root}
      component={InternalPaymentAndPlaceOrderStep}
    />
  );
};
